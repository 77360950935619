import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "F:/KimchiSwap/Final Kwikswap 25-12-2020/Main Repos/Beadswap/beadswapfrontend/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`When providing liquidity from a smart contract, the most important thing to keep in mind is that tokens deposited into a pool at any rate other than the current reserve ratio `}<em parentName="p">{`are vulnerable to being arbitraged`}</em>{`. As an example, if the ratio of x:y in a pair is 10:2 (i.e. the price is 5), and someone naively adds liquidity at 5:2 (a price of 2.5), the contract will simply accept all tokens (changing the price to 3.75 and opening up the market to arbitrage), but only issue pool tokens entitling the sender to the amount of assets sent at the proper ratio, in this case 5:1. To avoid donating to arbitrageurs, it is imperative to add liquidity at the current price. Luckily, it’s easy to ensure that this condition is met!`}</p>
    <h1 {...{
      "id": "using-the-router",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-the-router",
        "aria-label": "using the router permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using the Router`}</h1>
    <p>{`The easiest way to safely add liquidity to a pool is to use the `}<Link to='/docs/v1/smart-contracts/router02' mdxType="Link">{`router`}</Link>{`, which provides simple methods to safely add liquidity to a pool. If the liquidity is to be added to an BEP-20/BEP-20 pair, use `}<Link to='/docs/v1/smart-contracts/router02/#addliquidity' mdxType="Link">{`addLiquidity`}</Link>{`. If WBNB is involved, use `}<Link to='/docs/v1/smart-contracts/router02/#addliquidityeth' mdxType="Link">{`addLiquidityBNB`}</Link>{`.`}</p>
    <p>{`These methods both require the caller to commit to a `}<em parentName="p">{`belief about the current price`}</em>{`, which is encoded in the `}<inlineCode parentName="p">{`amount*Desired`}</inlineCode>{` parameters. Typically, it’s fairly safe to assume that the current fair market price is around what the current reserve ratio is for a pair (because of arbitrage). So, if a user wants to add 1 BNB to a pool, and the current DAI/WBNB ratio of the pool is 200/1, it’s reasonable to calculate that 200 DAI must be sent along with the BNB, which is an implicit commitment to the price of 200 DAI/1 WBNB. However, it’s important to note that this must be calculated `}<em parentName="p">{`before the transaction is submitted`}</em>{`. It is `}<em parentName="p">{`not safe`}</em>{` to look up the reserve ratio from within a transaction and rely on it as a price belief, as this ratio can be cheaply manipulated to your detriment.`}</p>
    <p>{`However, it is still possible to submit a transaction which encodes a belief about the price which ends up being wrong because of a larger change in the true market price before the transaction is confirmed. For that reason, it’s necessary to pass an additional set of parameters which encode the caller’s tolerance to price changes. These `}<inlineCode parentName="p">{`amount*Min`}</inlineCode>{` parameters should typically be set to percentages of the calculated desired price. So, at a 1% tolerance level, if our user sends a transaction with 1 BNB and 200 DAI, `}<inlineCode parentName="p">{`amountBNBMin`}</inlineCode>{` should be set to e.g. .99 BNB, and `}<inlineCode parentName="p">{`amountTokenMin`}</inlineCode>{` should be set to 198 DAI. This means that, at worst, liquidity will be added at a rate between 198 DAI/1 BNB and 202.02 DAI/1 BNB (200 DAI/.99 BNB).`}</p>
    <p>{`Once the price calculations have been made, it’s important to ensure that your contract a) controls at least as many tokens/BNB as were passed as `}<inlineCode parentName="p">{`amount*Desired`}</inlineCode>{` parameters, and b) has granted approval to the router to withdraw this many tokens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      